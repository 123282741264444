const config = {
  //  SERVER_URL: "http://localhost:3000",
  // SERVER_URL: "http://18.116.171.113:3000",
  // SERVER_URL: "https://staging.api.yandexgram.com:3000",
  SERVER_URL: "https://prodapi.linkgram.co.uk",
  // SERVER_URL: "http://18.217.220.173:3000",
  VERSION: "/api/v2/admin",
};

export default config;
