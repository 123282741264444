import Axios from "axios";
import jwtDecode from "jwt-decode";
import setAuthToken from "../setAuthToken";
import config from "./config";
import setIpAddress from "../setIpAddress";
const abortController = new AbortController();
const cancelToken = Axios.CancelToken.source(abortController.signal);
const axiosInstance = Axios.create({
  baseURL: `${config.SERVER_URL}`,
  cancelToken: cancelToken.token
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.isAdmin = true;
    return config;
  },
  (error) => {
   return Promise.reject(error);
  }
);

export const getApi = async (action, version = config.VERSION) => {
  try {
    if (localStorage.getItem("token")) {
      const payload = jwtDecode(localStorage.getItem("token"));

      if (payload.exp * 1000 < Date.now()) {
        await postRefreshToken();
      }
    }

    setAuthToken();
    const response = await axiosInstance.get(`${version}${action}`);// ,{ signal: abortController.signal }
    return response?.data;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return {message:'Request cancelled'};
    } else {
      return error.response?.data;
    }
  }
};

export const postApi = async (action, data, ip, version = config.VERSION) => {
  try {
    if (localStorage.getItem("token")) {
      const payload = jwtDecode(localStorage.getItem("token"));

      if (payload.exp * 1000 < Date.now()) {
        await postRefreshToken();
      }
    }

    setAuthToken();
    if (ip) setIpAddress(ip);
    const response = await axiosInstance.post(`${version}${action}`, data);

    if (response?.status) return response?.data;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return "Request Cancelled";
    } else {
      return error.response?.data;
    }
  }
};

export const postRefreshToken = async (version = config.VERSION) => {
  try {
    const refresh_token = localStorage.getItem("refresh_token");
    if (refresh_token) {
      const response = await axiosInstance.post(
        `${version}/admin/refresh-token`,
        { refresh_token }
      );
      if (response?.data?.success) {
        localStorage.setItem("token", response?.data?.data?.token);
        setAuthToken();
        return true;
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.setItem("session_expire", "Y");
        window.location.reload();
        return false;
      }
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.setItem("session_expire", "Y");
      window.location.reload();
      return false;
    }
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.setItem("session_expire", "Y");
    window.location.reload();
    return false;
  }
};

export const putApi = async (action, data, ip, version = config.VERSION) => {
  try {
    if (localStorage.getItem("token")) {
      const payload = jwtDecode(localStorage.getItem("token"));

      if (payload.exp * 1000 < Date.now()) {
        await postRefreshToken();
      }
    }

    setAuthToken();
    if (ip) setIpAddress(ip);
    const response = await axiosInstance.put(`${version}${action}`, data);
    return response?.data;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return "Request Cancelled";
    } else {
      return error.response?.data;
    }
  }
};

export const patchApi = async (action, data, ip, version = config.VERSION) => {
  try {
    if (localStorage.getItem("token")) {
      const payload = jwtDecode(localStorage.getItem("token"));

      if (payload.exp * 1000 < Date.now()) {
        await postRefreshToken();
      }
    }

    setAuthToken();
    if (ip) setIpAddress(ip);
    const response = await axiosInstance.patch(`${version}${action}`, data);
    return response?.data;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return "Request Cancelled";
    } else {
      return error.response?.data;
    }
  }
};

export const postFormData = async (
  action,
  formData,
  version = config.VERSION
) => {
  try {
    if (localStorage.getItem("token")) {
      const payload = jwtDecode(localStorage.getItem("token"));

      if (payload.exp * 1000 < Date.now()) {
        await postRefreshToken();
      }
    }

    setAuthToken();
    const response = await axiosInstance.post(`${version}${action}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return "Request Cancelled";
    } else {
      return error.response?.data;
    }
  }
};

export const deleteApi = async (action, version = config.VERSION) => {
  try {
    if (localStorage.getItem("token")) {
      const payload = jwtDecode(localStorage.getItem("token"));

      if (payload.exp * 1000 < Date.now()) {
        await postRefreshToken();
      }
    }

    setAuthToken();
    const response = await axiosInstance.delete(`${version}${action}`);
    return response?.data;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return "Request Cancelled";
    } else {
      return error.response?.data;
    }
  }
};

export const getExternalApi = async (action) => {
  try {
    const response = await axiosInstance.get(`${action}`);

    const data = {};
    if (response?.status === 200) data.status = true;
    else data.status = false;
    data.data = response?.data ? response?.data : null;
    data.message = response?.data?.message;

    return data;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return "Request Cancelled";
    } else {
      return error.response?.data;
    }
  }
};

export const cancelRequest = () => {
  abortController.abort();
};