import axios from "axios";

const setIpAddress = (ip) => {
  if (ip) {
    console.log("set ip: ", ip);
    axios.defaults.headers.common["ipAddress"] = ip;
  } else {
    axios.defaults.headers.common["ipAddress"] = null;
  }
};

export default setIpAddress;
