import {
  LikeOutlined,
  MessageOutlined,
  PlayCircleOutlined,
  ShareAltOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Layout,
  List,
  Row,
  Space,
  Tabs,
  message,
  Typography,
  Empty,
} from "antd";

import React, { useState } from "react";
import ViewComments from "./ViewComments";
import ViewProfiles from "./ViewProfiles";
import ViewReported from "./ViewReported";
import ViewDetails from "./ViewPostDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getPostById } from "./Post.service";
import ViewTaggedProfiles from "./ViewTaggedProfiles";
import { storageConstants } from "../../common/utils";
import { VideoJS } from "../common/video/Video";

const { Meta } = Card;
const { Paragraph } = Typography;

const ViewPost = () => {
  const { state } = useLocation();
  const [currentRef, setCurrentRef] = useState(state);
  const [tab, setTab] = useState("comments");
  const [postData, setPostData] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);
  const navigate = useNavigate();
  const playerRef = React.useRef(null);

  useEffect(() => {
    setCurrentRef(state);
    getPostData();
  }, []);

  const getPostData = () => {
    setCardLoading(true);
    getPostById(state.id, state.type)
      .then((data) => {
        setCardLoading(false);
        if (data && data.success) {
          setPostData(data.data);
        } else {
          message.error(data.message ? data.message : "failed");
        }
      })
      .catch((error) => {
        setCardLoading(false);
        message.error(error.message ? error.message : "failed");
      });
  };

  const items = [
    {
      key: "details",
      label: "Details",
      children: (
        <ViewDetails
          tab={tab}
          type={state.type}
          id={state.id}
          data={postData}
        />
      ),
    },
    {
      key: "comments",
      label: "Comments",
      children: (
        <ViewComments
          tab={tab}
          type={state.type}
          id={state.id}
          postOwner={postData?.user?.userId}
        />
      ),
    },
    {
      key: "likes",
      label: "Likes",
      children: <ViewProfiles tab={tab} type={state.type} id={state.id} />,
    },
    {
      key: "tags",
      label: "Tags",
      children: (
        <ViewTaggedProfiles
          tab={tab}
          type={state.type}
          id={state.id}
          data={postData}
        />
      ),
    },
    {
      key: "reported",
      label: "Reported Users",
      children: (
        <ViewReported
          tab={tab}
          type={state.type}
          id={state.id}
          data={postData}
        />
      ),
    },
  ];

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  const onTabChange = (data) => {
    console.log(data);
    setTab(data);
  };

  const navigateToUserProfile = (id) => {
    console.log(id);
    navigate(`${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${id}`);
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    console.log(player, "111111");
    // You can handle player events here, for example:
    player.on("waiting", () => {
      // videojs.log('player is waiting');
    });

    player.on("dispose", () => {
      // videojs.log('player will dispose');
    });
  };

  const getIconsList = (postData, type) => {
    let result = [];
    result = [
      <IconText
        icon={LikeOutlined}
        text={postData?.likes_count || 0}
        key="list-vertical-like-o"
      />,
      <IconText
        icon={MessageOutlined}
        text={postData?.comments_count || 0}
        key="list-vertical-message"
      />,
      <IconText icon={ShareAltOutlined} text="0" key="list-vertical-share-o" />,
    ];
    if (type && type === "reel") {
      result.push(
        <IconText
          icon={PlayCircleOutlined}
          text={postData?.views_count || 0}
          key="list-vertical-share-o"
        />
      );
    }
    return result;
  };

  return (
    <>
      <Layout style={{ background: "white" }}>
        <Row gutter={16}>
          <Col span={8}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Card
                style={{ width: 300 }}
                loading={cardLoading}
                cover={
                  <>
                    <Carousel>
                      {!cardLoading && postData && postData.type === "post" ? (
                        postData.fileData && postData.fileData.length ? (
                          postData.fileData.map((post) => {
                            return (
                              <img
                                alt="example"
                                src={post.filePath}
                                fallback={storageConstants.FALLBACK}
                              />
                            );
                          })
                        ) : (
                          <>
                            <Empty />
                          </>
                        )
                      ) : postData.fileData && postData.fileData.length ? (
                        postData.fileData.map((post) => {
                          return (
                            <VideoJS
                              options={{
                                autoplay: true,
                                controls: true,
                                responsive: true,
                                fluid: true,
                                muted: localStorage.getItem("isMute") || false,
                                disablePictureInPicture: true,
                                aspectRatio: "1:1",
                                poster: `${post.thumbnail || ""}`,
                                sources: [
                                  {
                                    src: `${post.filePath}`,
                                    type: "video/mp4",
                                  },
                                ],
                              }}
                              onReady={handlePlayerReady}
                            />
                          );
                        })
                      ) : (
                        <>
                          <Empty />
                        </>
                      )}
                    </Carousel>
                  </>
                }
                actions={getIconsList(postData, postData?.type)}
              >
                <Meta
                  avatar={
                    <>
                      {postData.user &&
                      postData.user.profilePictureDetails &&
                      postData.user.profilePictureDetails &&
                      postData.user.profilePictureDetails.filePath ? (
                        <Avatar
                          src={
                            postData.user &&
                            postData.user.profilePictureDetails &&
                            postData.user.profilePictureDetails &&
                            postData.user.profilePictureDetails.filePath
                          }
                        />
                      ) : (
                        <Avatar icon={<UserOutlined />} />
                      )}
                    </>
                  }
                  title={
                    <Button
                      type="link"
                      onClick={() => navigateToUserProfile(postData.user_id)}
                    >
                      {postData?.user?.userName || "-"}
                    </Button>
                  }
                  description={
                    <div
                      style={{
                        maxHeight: "200px",
                        overflow: "hidden",
                        overflowY: "scroll",
                      }}
                    >
                      <Paragraph
                        ellipsis={{ rows: 1, expandable: true, symbol: "more" }}
                      >
                        {postData.caption}
                      </Paragraph>
                    </div>
                  }
                />
              </Card>
            </div>
          </Col>
          <Col span={16}>
            <Tabs
              defaultActiveKey={tab}
              items={items}
              forceRender
              onChange={onTabChange}
            />
          </Col>
        </Row>
      </Layout>
    </>
  );
};
export default ViewPost;
