import React from "react";
import { Spin } from "antd";
import { bindActionCreators } from "redux";
import { operations } from "./redux/Main";
import { connect } from "react-redux";
import { BaseLayout } from "./components/common";
import Routes from "./Routes";
const App = (props) => {
  const isAuthenticated = props.user?.isAuthenticated || localStorage.getItem('token') ? true : false;
  // const isAuthenticated = true;

  return (
    <>
      {isAuthenticated ? (
        <BaseLayout
          {...props}
          content={
            <React.Suspense fallback={<Spin />}>
              <Routes {...props} />
            </React.Suspense>
          }
        ></BaseLayout>
      ) : (
        <React.Suspense fallback={<Spin />}>
          <Routes {...props} />
        </React.Suspense>
      )}
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    user: state.mainReducers.main.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
