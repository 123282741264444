import { getApi, deleteApi } from "../../services/api";

export const getPaginatedContent = (tableParams, tab) => {
  const { current = 1, pageSize = 10 } = tableParams.pagination;
  return getApi(
    `/content-reports-admin?page=${current}&pageSize=${pageSize}&type=${tab}`,
    "/api/v1/feed"
  );
};

export const getReportedPaginatedUsers = (tableParams) => {
  const { current = 1, pageSize = 10 } = tableParams.pagination;
  return getApi(
    `/report/users?page=${current}&pageSize=${pageSize}`,
    "/api/v1"
  );
};

export const getReportedUsersForUser = (id, page, pageSize) => {
  return getApi(
    `/report/user/${id}/users?page=${page}&pageSize=${pageSize}`,
    "/api/v1"
  );
};
