import React, { lazy } from "react";
import { useRoutes, useNavigate, Navigate } from "react-router-dom";
import { storageConstants } from "./common/utils/constants";
import ViewRequestVerification from "./components/UserManagement/RequestVerification/ViewRequestVerification";
import StoryManagement from "./components/StoryManagement/StoryManagement";
import ContentReportManagement from "./components/ContentReportManagement/ContentReportMangement";
import ViewPost from "./components/Post/ViewPost";
import Blocklist from "./components/Blocklist/Blocklist";

const Login = lazy(() => import("./components/Login/Login"));
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const PostManagement = lazy(() =>
  import("./components/PostManagement/PostManagement")
);
const UserManagement = lazy(() =>
  import("./components/UserManagement/UserManagement")
);
const ContentModerationManagement = lazy(() =>
  import("./components/ContentModerationManagement/ContentModerationManagement")
);
const ContentDetails = lazy(() =>
  import("./components/ContentModerationManagement/ContentDetails")
);
const RequestVerification = lazy(() =>
  import("./components/UserManagement/RequestVerification/RequestVerification")
);

const Profile = lazy(() => import("./components/Profile/Profile"));
const SystemSettings = lazy(() =>
  import("./components/SystemSettings/SystemSettings")
);

const ViewAccount = lazy(() =>
  import("./components/Accounts/ViewAccount/ViewAccount")
);
const NotFound = lazy(() => import("./components/common/error/NotFoundError"));
const InternalServerError = lazy(() =>
  import("./components/common/error/InternalServerError")
);
const UserDetails = lazy(() =>
  import("./components/UserManagement/UserDetails")
);

const Notifications = lazy(() =>
  import("./components/Notifications/Notifications")
);

const ReelManagement = lazy(() =>
  import("./components/ReelManagement/ReelManagement")
);

const Hashtags = lazy(() => import("./components/Hashtags/Hashtags"));
const HashtagDetails = lazy(() =>
  import("./components/Hashtags/HashtagDetails")
);

const authenticatedRoutes = (props) => {
  return [
    {
      path: storageConstants.PAGES.ROUTE.DASHBOARD,
      element: <Dashboard {...props} />,
    },
    {
      path: storageConstants.PAGES.ROUTE.USER_MANAGEMENT,
      // element: <UserManagement {...props} exact/>,
      children: [
        {
          index: true,
          //  path: "request-verification",
          element: <UserManagement {...props} />,
        },
        {
          path: "view-request-verification/:id",
          element: <ViewRequestVerification {...props} />,
        },
        {
          path: "request-verification",
          element: <RequestVerification {...props} />,
        },
        {
          path: `user/:id`,
          element: <UserDetails {...props} />,
        },
      ],
    },
    {
      path: storageConstants.PAGES.ROUTE.POST_MANAGEMENT,
      children: [
        {
          index: true,
          element: <PostManagement {...props} />,
        },
      ],
    },
    {
      path: storageConstants.PAGES.ROUTE.REEL_MANAGEMENT,
      children: [
        {
          index: true,
          element: <ReelManagement {...props} />,
        },
        // {
        // 	path: `reel/:id`,
        // 	element: <UserDetails {...props} />,
        // },
      ],
    },
    {
      path: storageConstants.PAGES.ROUTE.STORY_MANAGEMENT,
      children: [
        {
          index: true,
          element: <StoryManagement {...props} />,
        },
        // {
        // 	path: `reel/:id`,
        // 	element: <UserDetails {...props} />,
        // },
      ],
    },
    {
      path: storageConstants.PAGES.ROUTE.CONTENT_REPORT_MANAGEMENT,
      children: [
        {
          index: true,
          element: <ContentReportManagement {...props} />,
        },
      ],
    },

    {
      path: storageConstants.PAGES.ROUTE.PROFILE,
      element: <Profile {...props} />,
    },
    {
      path: storageConstants.PAGES.ROUTE.SYSTEM_SETTINGS_AND_CONFIGURATION,
      element: <SystemSettings {...props} />,
    },
    {
      path: storageConstants.PAGES.ROUTE.ACCOUNTS,
      element: <ViewAccount {...props} />,
    },
    {
      path: "post",
      element: <ViewPost {...props} />,
    },
    {
      path: storageConstants.PAGES.ROUTE.BLOCKLIST,
      element: <Blocklist {...props} />,
    },
    {
      path: "500",
      element: <InternalServerError {...props} />,
    },
    {
      path: "*",
      element: <NotFound {...props} />,
    },
    {
      path: storageConstants.PAGES.ROUTE.NOTIFICATIONS,
      // element: <Notifications {...props} exact/>,
      children: [
        {
          index: true,
          //  path: "request-verification",
          element: <Notifications {...props} />,
        },
      ],
    },
    {
      path: storageConstants.PAGES.ROUTE.CONTENT_MODERATION_MANAGEMENT,
      children: [
        {
          index: true,
          element: <ContentModerationManagement {...props} />,
        },
        {
          path: `content/:id`,
          element: <ContentDetails {...props} />,
        },
      ],
    },
    {
      path: storageConstants.PAGES.ROUTE.HASHTAGS,
      // element: <Notifications {...props} exact/>,
      children: [
        {
          index: true,
          //  path: "request-verification",
          element: <Hashtags {...props} />,
        },
        {
          path: `details/:id`,
          element: <HashtagDetails {...props} />,
        },
      ],
    },
  ];
};

const unauthenticatedRoutes = (props) => {
  return [
    {
      path: storageConstants.PAGES.ROUTE.LOGIN,
      element: <Login {...props} />,
    },
  ];
};

const Routes = (props) => {
  const navigate = useNavigate();
  const isAuthenticated =
    props.user?.isAuthenticated || localStorage.getItem("token") ? true : false;
  // const isAuthenticated = true;

  const routingConfig = useRoutes(
    isAuthenticated ? authenticatedRoutes(props) : unauthenticatedRoutes(props)
    // isAuthenticated ? authenticatedRoutes : unauthenticatedRoutes
  );

  // Redirect to login if user is not authenticated
  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate(storageConstants.PAGES.ROUTE.LOGIN);
    }
  }, [navigate]);

  return routingConfig;
};

export default Routes;
