import {
  HeartOutlined,
  HeartTwoTone,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Layout,
  List,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import VirtualList from "rc-virtual-list";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFormatDateWithTimeStamp,
  storageConstants,
} from "../../common/utils";
import UserTooltip from "../common/user-tooltip/userTooltip";
import CommentReactionList from "./CommentReactionList";
import {
  getPostCommentsByPostId,
  getReelCommentsByReelId,
} from "./Post.service";

const ContainerHeight = 400;
const { Paragraph, Text } = Typography;

const ViewComments = ({ tab, type, id, postOwner }) => {
  const [data, setData] = useState([]);
  const [postId, setPostId] = useState(id);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [commentLoading, setCommentLoading] = useState(false);
  const [reactionModal, setReactionModal] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [subCommentsData, setSubCommentsData] = useState({});
  const navigate = useNavigate();

  const appendData = () => {
    setCommentLoading(true);
    const offset = currentPage;
    if (type === "post") {
      getPostCommentsByPostId(id, offset, pageSize)
        .then((data) => {
          formatData(data);
        })
        .catch((error) => {
          setCommentLoading(false);
          message.error(error.message ? error.message : "failed");
        });
    } else if (type === "reel") {
      getReelCommentsByReelId(id, offset, pageSize)
        .then((data) => {
          formatData(data);
        })
        .catch((error) => {
          setCommentLoading(false);
          message.error(error.message ? error.message : "failed");
        });
    }
  };

  const formatSubCommentData = (commentId, data) => {
    if (data && data.success) {
      let updatedObject = { ...subCommentsData };
      // if (updatedObject[commentId]) {
      const previousData =
        subCommentsData?.[commentId] && subCommentsData?.[commentId].data
          ? subCommentsData?.[commentId].data
          : [];
      updatedObject[commentId] = {
        data: [...previousData, ...data.data.rows] || [],
        page:
          (subCommentsData?.[commentId] && subCommentsData?.[commentId].page) ||
          1,
        pageSize:
          (subCommentsData?.[commentId] &&
            subCommentsData?.[commentId].pageSize) ||
          10,
        show: true,
        loading: false,
        total: data.data.count || 0,
      };
      // updatedObject[commentId].data =
      //   [{ ...subCommentsData?.[commentId].data, ...data.data.rows }] || [];
      // updatedObject[commentId].page =
      //   subCommentsData?.[commentId]?.page + 1 || 1;
      // updatedObject[commentId].pageSize =
      //   subCommentsData?.[commentId]?.pageSize || 10;
      setSubCommentsData(updatedObject);
    } else {
      message.error(data.message ? data.message : "failed");
    }
  };

  const formatData = (data) => {
    setCommentLoading(false);
    if (data && data.success) {
      setData((prevData) => [...prevData, ...data.data.rows]);
      setTotalRecords(data.data.count);
      setCurrentPage((prevPage) => prevPage + 1);
      message.success(`${data.data.rows.length} more comments loaded!`);
    } else {
      message.error(data.message ? data.message : "failed");
    }
  };

  const subCommentsFetch = (commentId, subCommentPage = 1) => {
    if (type === "post") {
      getPostCommentsByPostId(postId, subCommentPage, pageSize, commentId)
        .then((data) => {
          formatSubCommentData(commentId, data);
        })
        .catch((error) => {
          setCommentLoading(false);
          message.error(error.message ? error.message : "failed");
        });
    } else if (type === "reel") {
      getReelCommentsByReelId(postId, subCommentPage, pageSize, commentId)
        .then((data) => {
          formatSubCommentData(commentId, data);
        })
        .catch((error) => {
          setCommentLoading(false);
          message.error(error.message ? error.message : "failed");
        });
    }
  };

  const subCommentsPaginationHandler = (commentId, show = true) => {
    // let updatedObject = { ...subCommentsData };

    // // updatedObject[commentId].data =
    // //   [{ ...subCommentsData?.[commentId].data, ...data.data.rows }] || [];
    // updatedObject[commentId] = {
    //   data: subCommentsData?.[commentId]?.data || [],
    //   page:
    //     Math.ceil(
    //       subCommentsData?.[commentId]?.total /
    //         subCommentsData?.[commentId]?.pageSize
    //     ) || 1,
    //   pageSize: subCommentsData?.[commentId]?.pageSize || 10,
    //   show: show,
    //   loading: true,
    //   total: 0,
    // };
    // // subCommentsData?.[commentId]?.page + 1 || 1;
    // // updatedObject[commentId].pageSize =
    // //   subCommentsData?.[commentId]?.pageSize || 10;
    // setSubCommentsData(updatedObject);
    // }
    // setSubCommentsLoading((prevData) => [
    //   ...prevData,
    //   {
    //     [commentId]: {
    // data: subCommentsData?.[commentId]?.data || [],
    // page: subCommentsData?.[commentId]?.page || 1,
    // pageSize: subCommentsData?.[commentId]?.pageSize || 10,
    // show: show,
    // loading: true,
    //     },
    //   },
    // ]);
    let updatedObject = { ...subCommentsData };
    if (show) {
      // updatedObject[commentId].data =
      //   [{ ...subCommentsData?.[commentId].data, ...data.data.rows }] || [];
      updatedObject[commentId] = {
        data: subCommentsData?.[commentId]?.data || [],
        page:
          Math.ceil(
            subCommentsData?.[commentId]?.total /
              subCommentsData?.[commentId]?.pageSize
          ) || 1,
        pageSize: subCommentsData?.[commentId]?.pageSize || 10,
        show: show,
        loading: true,
        total: 0,
      };
      // subCommentsData?.[commentId]?.page + 1 || 1;
      // updatedObject[commentId].pageSize =
      //   subCommentsData?.[commentId]?.pageSize || 10;
      setSubCommentsData(updatedObject);
      subCommentsFetch(commentId);
    } else {
      updatedObject[commentId] = {};
      setSubCommentsData(updatedObject);
    }
  };

  useEffect(() => {
    if (tab === "comments") {
      setData([]);
      setCurrentPage(1);
      setPageSize(10);
      setTotalRecords(0);
      appendData();
    }
    return () => {
      setData([]);
      setCurrentPage(1);
      setPageSize(10);
      setTotalRecords(0);
    };
  }, [tab]);

  const onScroll = (e) => {
    const container = e.currentTarget;
    if (container) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const currentTotal = (currentPage - 1) * pageSize;
      if (
        scrollHeight - scrollTop === clientHeight &&
        currentTotal < totalRecords
      ) {
        appendData();
      }
    }
  };

  const reactionUserListModal = (id) => {
    setCommentId(id);
    setReactionModal(true);
  };

  const incrementSubCommentPage = (commentId) => {
    let updatedObject = { ...subCommentsData };

    // updatedObject[commentId].data =
    //   [{ ...subCommentsData?.[commentId].data, ...data.data.rows }] || [];
    updatedObject[commentId] = {
      ...updatedObject[commentId],
      page:
        (subCommentsData?.[commentId] &&
          subCommentsData?.[commentId].page + 1) ||
        1,
    };
    // subCommentsData?.[commentId]?.page + 1 || 1;
    // updatedObject[commentId].pageSize =
    //   subCommentsData?.[commentId]?.pageSize || 10;
    setSubCommentsData(updatedObject);
    subCommentsFetch(commentId, subCommentsData?.[commentId].page + 1);
  };

  const loadMore = (id) => {
    return subCommentsData[id].show &&
      subCommentsData[id].data &&
      subCommentsData[id].total &&
      subCommentsData[id].data.length < subCommentsData[id].total ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button
          onClick={() => {
            incrementSubCommentPage(id);
          }}
        >
          view more
        </Button>
      </div>
    ) : null;
  };
  return (
    <>
      <Layout style={{ background: "white" }}>
        {totalRecords > 0 ? (
          <List
            // itemLayout="vertical"
            bordered
            loading={commentLoading}
            footer={
              <div style={{ float: "right" }}>
                <b>Total: {totalRecords}</b>
              </div>
            }
          >
            <VirtualList
              data={data}
              height={ContainerHeight}
              itemHeight={47}
              itemCount={data.length}
              itemKey="id"
              onScroll={onScroll}
            >
              {(item) => (
                <>
                  <List.Item
                    key={item.id}
                    // actions={[
                    //   <Space
                    //     onClick={() => reactionUserListModal(item.id)}
                    //     style={{ cursor: "pointer" }}
                    //   >
                    //     {item?.has_comment_like ? (
                    //       <HeartTwoTone twoToneColor="#eb2f96" />
                    //     ) : (
                    //       <HeartOutlined />
                    //     )}
                    //     {item?.comment_like_count || 0}
                    //   </Space>,
                    //   <Space>
                    //     <MessageOutlined />
                    //     {item?.subcomments_count || 0}
                    //   </Space>,
                    // ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <>
                          {item.user &&
                          item.user.profilePictureDetails &&
                          item.user.profilePictureDetails &&
                          item.user.profilePictureDetails.filePath ? (
                            <Avatar
                              src={
                                item.user &&
                                item.user.profilePictureDetails &&
                                item.user.profilePictureDetails &&
                                item.user.profilePictureDetails.filePath
                              }
                            />
                          ) : (
                            <Avatar icon={<UserOutlined />} />
                          )}
                        </>
                      }
                      title={
                        <Space split={<Divider type="vertical" />}>
                          <a
                            onClick={() => {
                              navigate(
                                `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${item.userId}`
                              );
                            }}
                            style={{ color: "black" }}
                          >
                            <UserTooltip
                              userName={item?.user?.userName || "-"}
                              userId={item.userId}
                              verified={item?.user?.verified}
                            />
                          </a>
                          <Text type="secondary" style={{ fontSize: "10px" }}>
                            {" "}
                            {(item?.createdAt &&
                              getFormatDateWithTimeStamp(item?.createdAt)) ||
                              "-"}
                          </Text>
                        </Space>
                      }
                      description={
                        <>
                          <Row justify="space-between">
                            <Col span={4}>
                              {" "}
                              <Paragraph
                                ellipsis={{
                                  rows: 1,
                                  expandable: true,
                                  symbol: "more",
                                }}
                              >
                                {item.comment}
                              </Paragraph>
                            </Col>
                            <Col span={4}>
                              {" "}
                              <Space split={<Divider type="vertical" />}>
                                <Space
                                  onClick={() => reactionUserListModal(item.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item?.has_comment_like ? (
                                    <HeartTwoTone twoToneColor="#eb2f96" />
                                  ) : (
                                    <HeartOutlined />
                                  )}
                                  {item?.comment_like_count || 0}
                                </Space>
                                <Space>
                                  <MessageOutlined />
                                  {item?.subcomments_count || 0}
                                </Space>
                              </Space>
                            </Col>
                          </Row>
                          {item?.subcomments && (
                            <>
                              <Divider orientation="left" plain>
                                <Text
                                  type="secondary"
                                  style={{
                                    fontSize: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    subCommentsPaginationHandler(
                                      item.id,
                                      !subCommentsData[item.id]?.show
                                    )
                                  }
                                >
                                  {" "}
                                  {subCommentsData[item.id]?.show
                                    ? "Hide"
                                    : "View Replies " + item?.subcomments_count}
                                </Text>
                              </Divider>

                              {subCommentsData[item.id]?.show && (
                                <>
                                  <List
                                    className="demo-loadmore-list"
                                    loading={
                                      subCommentsData[item.id]?.loading || false
                                    }
                                    itemLayout="horizontal"
                                    loadMore={loadMore(item.id)}
                                    dataSource={subCommentsData[item.id]?.data}
                                    renderItem={(subcomment) => (
                                      <List.Item
                                      // actions={[
                                      //   <Space
                                      //     onClick={() =>
                                      //       reactionUserListModal(
                                      //         subcomment.id
                                      //       )
                                      //     }
                                      //     style={{ cursor: "pointer" }}
                                      //   >
                                      //     {subcomment?.has_comment_like ? (
                                      //       <HeartTwoTone twoToneColor="#eb2f96" />
                                      //     ) : (
                                      //       <HeartOutlined />
                                      //     )}
                                      //     {subcomment?.comment_like_count ||
                                      //       0}
                                      //   </Space>,
                                      // ]}
                                      >
                                        <List.Item.Meta
                                          avatar={
                                            <>
                                              {subcomment.user &&
                                              subcomment.user
                                                .profilePictureDetails &&
                                              subcomment.user
                                                .profilePictureDetails &&
                                              subcomment.user
                                                .profilePictureDetails
                                                .filePath ? (
                                                <Avatar
                                                  src={
                                                    subcomment.user &&
                                                    subcomment.user
                                                      .profilePictureDetails &&
                                                    subcomment.user
                                                      .profilePictureDetails &&
                                                    subcomment.user
                                                      .profilePictureDetails
                                                      .filePath
                                                  }
                                                />
                                              ) : (
                                                <Avatar
                                                  icon={<UserOutlined />}
                                                />
                                              )}
                                            </>
                                          }
                                          title={
                                            <Space
                                              split={
                                                <Divider type="vertical" />
                                              }
                                            >
                                              <a
                                                onClick={() => {
                                                  navigate(
                                                    `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${subcomment.userId}`
                                                  );
                                                }}
                                                style={{ color: "black" }}
                                              >
                                                <UserTooltip
                                                  userName={
                                                    subcomment?.user
                                                      ?.userName || "-"
                                                  }
                                                  userId={subcomment.userId}
                                                  verified={
                                                    subcomment?.user?.verified
                                                  }
                                                />
                                              </a>
                                              <Text
                                                type="secondary"
                                                style={{ fontSize: "10px" }}
                                              >
                                                {" "}
                                                {(subcomment?.createdAt &&
                                                  getFormatDateWithTimeStamp(
                                                    subcomment?.createdAt
                                                  )) ||
                                                  "-"}
                                              </Text>
                                            </Space>
                                          }
                                          description={
                                            <Row justify="space-between">
                                              <Col span={10}>
                                                {" "}
                                                <Paragraph
                                                  ellipsis={{
                                                    rows: 1,
                                                    expandable: true,
                                                    symbol: "more",
                                                  }}
                                                >
                                                  {subcomment.comment}
                                                </Paragraph>
                                              </Col>
                                              <Col span={2}>
                                                <Space
                                                  onClick={() =>
                                                    reactionUserListModal(
                                                      subcomment.id
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {subcomment?.has_comment_like ? (
                                                    <HeartTwoTone twoToneColor="#eb2f96" />
                                                  ) : (
                                                    <HeartOutlined />
                                                  )}
                                                  {subcomment?.comment_like_count ||
                                                    0}
                                                </Space>
                                              </Col>
                                            </Row>
                                          }
                                        />
                                      </List.Item>
                                    )}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                      }
                    />
                  </List.Item>
                </>
              )}
            </VirtualList>
          </List>
        ) : (
          <Card>
            <Empty></Empty>
          </Card>
        )}

        {reactionModal && (
          <CommentReactionList
            modalVisible={reactionModal}
            setModalVisible={setReactionModal}
            id={commentId}
            postOwner={postOwner}
          />
        )}
      </Layout>
    </>
  );
};
export default ViewComments;
