import { Modal } from "antd";
export const Image = ({ url, type }) => {
  return (
    <>
      {type === "pdf" && <iframe src={url} width="100%" height="600"></iframe>}
      {type === "image" && (
        <img alt="" src={url} style={{ width: "100%", height: 600 }} />
      )}
    </>
  );
};
