import { EyeOutlined } from "@ant-design/icons";
import { Button, Card, Layout, Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPaginatedContent } from "./ContentReportManagement.service";
import { cancelRequest } from "../../services/api";
import UserTooltip from "../common/user-tooltip/userTooltip";
import { storageConstants } from "../../common/utils";
const { Content } = Layout;

const ContentReportTable = (props) => {
  const navigate = useNavigate();
  const [reportedData, setReportedData] = useState([]);
  const [tab, setTab] = useState(props.tab);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
      pageSize: 10,
      current: 1,
    },
  });

  useEffect(() => {
    reset();
    setTrigger(true);
    return () => {
      cancelRequest();
    };
  }, [props.tab]);

  useEffect(() => {
    if (trigger) {
      getContent();
    }
    return () => {
      cancelRequest();
    };
  }, [JSON.stringify(tableParams), props.tab]);

  const reset = () => {
    setTableParams({
      pagination: {
        page: 1,
        pageSize: 10,
        current: 1,
      },
    });
    setReportedData([]);
  };

  const getContent = () => {
    setLoading(true);
    getPaginatedContent(getTableParams(tableParams), tab)
      .then((data) => {
        if (data && data.success) {
          setTotalRecords(data.data.count);
          setReportedData(data.data.rows);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: data.data.count,
            },
          });
        } else {
          message.error(data.message ? data.message : "failed");
        }
      })
      .catch((error) => {
        message.error(error.message ? error.message : "failed");
      })
      .finally(() => {
        setTrigger(false);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: tab === "post" ? "Posts" : "Reels",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (_, record) => (
        <>
          {record.post.thumbnail && (
            <img src={record.post.thumbnail} height={100} width={100} />
          )}
        </>
      ),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      render: (text, record) => (
        <a
          onClick={() => {
            navigate(
              `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${record?.post?.user?.userId}`
            );
          }}
        >
          <UserTooltip
            userName={record?.post?.user?.userName}
            userId={record?.post?.user?.userId}
            verified={record?.post?.user?.verified}
          />
        </a>
      ),
    },
    {
      title: "Report Count",
      dataIndex: "count_of_reports",
      key: "count_of_reports",
    },
    {
      title: "Likes",
      dataIndex: "likes_count",
      key: "likes_count",
      render: (_, record) => <>{record?.post?.likes_count || 0}</>,
    },
    {
      title: "Comments",
      dataIndex: "comments_count",
      key: "comments_count",
      render: (_, record) => <>{record?.post?.comments_count || 0}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            shape="circle"
            onClick={(e) =>
              navigate(`/post`, { state: { id: record.post_id, type: tab } })
            }
          >
            <EyeOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const getTitle = (tab) => {
    if (tab === "reel") {
      return `Reported Reels`;
    } else if (tab === "post") {
      return `Reported Posts`;
    } else return "";
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTrigger(true);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getTableParams = (params) => ({
    pageSize: params.pagination?.pageSize,
    current: params.pagination?.current,
    ...params,
  });

  return (
    <Content>
      <Card title={getTitle(props.tab)}>
        <Table
          columns={columns}
          dataSource={reportedData}
          pagination={tableParams.pagination}
          loading={loading}
          footer={() => (
            <>
              <b>Total: {totalRecords}</b>
            </>
          )}
          onChange={handleTableChange}
          scroll={{
            y: 400,
          }}
        />
      </Card>
    </Content>
  );
};
export default ContentReportTable;
