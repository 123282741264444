import { Avatar, Card, Descriptions, Layout, List, message } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import VirtualList from "rc-virtual-list";
import {
  getFormatDateWithTimeStamp,
  storageConstants,
} from "../../common/utils";
import { useNavigate } from "react-router-dom";
import UserTooltip from "../common/user-tooltip/userTooltip";

const ViewDetails = ({ tab, type, id, data }) => {
  const navigate = useNavigate();
  const [details, setDetails] = useState(data);

  useEffect(() => {
    if (tab === "details") {
      setDetails(data);
    }
  }, [tab]);

  useEffect(() => {
    return () => {
      setDetails([]);
    };
  }, []);
  return (
    <>
      <Layout style={{ background: "white" }}>
        <Descriptions title="Info" bordered>
          <Descriptions.Item label="Type">
            {details.type.toUpperCase() || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Full Name">
            {details?.user?.fullName || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Uploaded By">
            <a
              onClick={() => {
                navigate(
                  `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${details.user_id}`
                );
              }}
            >
              <UserTooltip
                userName={details?.user?.userName || "-"}
                userId={details?.user_id}
                verified={details?.user?.verified}
              />
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {details.createdAt
              ? getFormatDateWithTimeStamp(details.createdAt)
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {details.updatedAt
              ? getFormatDateWithTimeStamp(details.updatedAt)
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Deleted At">
            {details.deletedAt
              ? getFormatDateWithTimeStamp(details.deletedAt)
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Likes">
            {details.likes_count || 0}
          </Descriptions.Item>
          <Descriptions.Item label="Comments">
            {details.comments_count || 0}
          </Descriptions.Item>
          <Descriptions.Item label="Location">
            {details.location || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Longitude">
            {details.longitude || 0}
          </Descriptions.Item>
          <Descriptions.Item label="Latitude">
            {details.latitude || 0}
          </Descriptions.Item>
        </Descriptions>
      </Layout>
    </>
  );
};
export default ViewDetails;
