import { formatTablePayload } from "../../common/utils";
import { getApi, postApi } from "../../services/api";

export const getPaginatedBlockList = (tableParams) => {
  const data = {
    ...formatTablePayload(tableParams),
  };
  return postApi(`/blocklist-admin`, data, null, "/api/v1/user");
};

export const getPaginatedBlockListOfUser = (
  id,
  type,
  page,
  pageSize,
  search = null
) => {
  return getApi(
    `/blocklist-admin/user/${id}?page=${page}&pageSize=${pageSize}&type=${type}&search=${search}`,
    "/api/v1/user"
  );
};
