import { Layout, Tabs } from "antd";
import { useState } from "react";
import ContentReportTable from "./ContentReportTable";
import UserReportTable from "./UserReportTable";
const { Content } = Layout;

const ContentReportManagement = () => {
  const [tab, setTab] = useState("post");

  const items = [
    {
      key: "post",
      label: "Posts",
      children: <ContentReportTable tab={tab} />,
    },
    {
      key: "reel",
      label: "Reels",
      children: <ContentReportTable tab={tab} />,
    },
    {
      key: "user",
      label: "Users",
      children: <UserReportTable tab={tab} />,
    },
  ];

  const onTabChange = (data) => {
    setTab(data);
  };

  return (
    <>
      <Layout style={{ background: "white" }}>
        <Tabs defaultActiveKey={tab} items={items} onChange={onTabChange} />
      </Layout>
    </>
  );
};
export default ContentReportManagement;
