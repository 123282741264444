import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Descriptions,
  Popover,
  Space,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import { getProfileById } from "./userTooltip.service";
import { BsFillPatchCheckFill } from "react-icons/bs";

const UserTooltip = ({ userName, userId, verified = false }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const openToolTip = (event, id) => {
    if (event) {
      setLoading(true);
      setData({});
      getProfileById(id)
        .then((data) => {
          if (data && data.success) {
            setData(data.data);
            setLoading(false);
          } else {
            message.error(data.message ? data.message : "failed");
            setLoading(false);
          }
        })
        .catch((error) => {
          message.error(error.message ? error.message : "failed");
          setLoading(false);
        });
    }
  };

  const followList = () => {
    return (
      <>
        <Space>
          <Badge color={data?.isPrivate ? "blue" : "green"} />
          {data &&
          data.profilePictureDetails &&
          data.profilePictureDetails.filePath ? (
            <Avatar src={data.profilePictureDetails.filePath} />
          ) : (
            <Avatar icon={<UserOutlined />} />
          )}
          <Typography.Text>
            {data?.fullName + " " || "-"}
            {data?.verified && (
              <BsFillPatchCheckFill style={{ color: "blue" }} />
            )}
          </Typography.Text>
        </Space>
        <div style={{ marginTop: "5px" }}>
          <Descriptions bordered size={"small"} column={1}>
            <Descriptions.Item label="Following">
              {data?.follow?.following || 0}
            </Descriptions.Item>
            <Descriptions.Item label="Follower">
              {data?.follow?.followers || 0}
            </Descriptions.Item>
            <Descriptions.Item label="Posts">
              {data?.content?.post || 0}
            </Descriptions.Item>
            <Descriptions.Item label="Reels">
              {data?.content?.reel || 0}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </>
    );
  };
  return (
    <>
      <Popover
        placement="top"
        content={loading ? <LoadingOutlined /> : followList()}
        onOpenChange={(e) => openToolTip(e, userId)}
      >
        @{userName || "-"}{" "}
        {verified && <BsFillPatchCheckFill style={{ color: "blue" }} />}
      </Popover>
    </>
  );
};

export default UserTooltip;
