import { getApi, deleteApi, postApi } from "../../services/api";

export const getPostById = (id, type) =>
  getApi(`/admin/post/${id}?type=${type}`, "/api/v1/feed");

export const getReelCommentsByReelId = (id, page, pageSize, cid = null) => {
  if (cid) {
    return getApi(
      `/admin/reel/${id}/comments?page=${page}&pageSize=${pageSize}&cid=${cid}`,
      "/api/v1/feed"
    );
  } else {
    return getApi(
      `/admin/reel/${id}/comments?page=${page}&pageSize=${pageSize}`,
      "/api/v1/feed"
    );
  }
};

export const getPostCommentsByPostId = (id, page, pageSize, cid = null) => {
  if (cid) {
    return getApi(
      `/admin/post/${id}/comments?page=${page}&pageSize=${pageSize}&cid=${cid}`,
      "/api/v1/feed"
    );
  } else {
    return getApi(
      `/admin/post/${id}/comments?page=${page}&pageSize=${pageSize}`,
      "/api/v1/feed"
    );
  }
};

export const getReelLikesByReelId = (id, page, pageSize) => {
  return getApi(
    `/admin/reel/${id}/reaction/users?page=${page}&pageSize=${pageSize}`,
    "/api/v1/feed"
  );
};

export const getPostLikesByPostId = (id, page, pageSize) => {
  return getApi(
    `/admin/post/${id}/reaction/users?page=${page}&pageSize=${pageSize}`,
    "/api/v1/feed"
  );
};

export const deletePostByAdmin = (id) => {
  return deleteApi(`/posts/${id}`, "/api/v1/feed");
};

export const deleteReelByAdmin = (id) => {
  return deleteApi(`/reels/${id}`, "/api/v1/feed");
};

export const getTaggedUsers = (id, data) => {
  return postApi(`/user-profiles?id=${id}`, data, null, "/api/v1");
};

export const getReportedUsers = (id, page, pageSize) => {
  return getApi(
    `/post/${id}/reported-user?page=${page}&pageSize=${pageSize}`,
    "/api/v1/feed"
  );
};

export const getReportedComments = (id, page, pageSize, type) => {
  return getApi(
    `/post/${id}/reported-comments?page=${page}&pageSize=${pageSize}&type=${type}`,
    "/api/v1/feed"
  );
};

export const getReportedCommentUsers = (id, page, pageSize) => {
  return getApi(
    `/post/comment/${id}/reported-comment-users?page=${page}&pageSize=${pageSize}`,
    "/api/v1/feed"
  );
};

export const getReactionUsersList = (id, page, pageSize, postOwner) => {
  return getApi(
    `/admin/comment/${id}/reaction?page=${page}&pageSize=${pageSize}&userId=${postOwner}`,
    "/api/v1/feed"
  );
};
