import { UserOutlined } from "@ant-design/icons";
import { Avatar, Empty, Layout, List, Modal, message } from "antd";
import VirtualList from "rc-virtual-list";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    getFormatDateWithTimeStamp,
    storageConstants,
} from "../../common/utils";
import UserTooltip from "../common/user-tooltip/userTooltip";
import { getReactionUsersList } from "./Post.service";

const ContainerHeight = 400;
const { Content } = Layout;

const CommentReactionList = ({
  modalVisible,
  setModalVisible,
  id,
  postOwner = null,
}) => {
  console.log(modalVisible, id, postOwner);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [profileLoading, setProfileLoading] = useState(false);

  useEffect(() => {
    if (id && modalVisible && postOwner) {
      appendData(id, 1);
    }
    return () => {
      resetData();
    };
  }, [modalVisible]);

  const appendData = (id, offset) => {
    setProfileLoading(true);
    setTotalRecords(0);
    getReactionUsersList(id, offset, pageSize, postOwner)
      .then((data) => {
        formatData(data);
      })
      .catch((error) => {
        setProfileLoading(false);
        message.error(error.message ? error.message : "failed");
      });
  };

  const formatData = (data) => {
    setProfileLoading(false);
    if (data && data.success) {
      setData((prevData) => [...prevData, ...data.data.rows]);
      setTotalRecords(data.data.count);
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      message.error(data.message ? data.message : "failed");
    }
  };
  const onScroll = (e) => {
    const container = e.currentTarget;
    if (container) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const currentTotal = (currentPage - 1) * pageSize;
      if (
        scrollHeight - scrollTop === clientHeight &&
        currentTotal < totalRecords
      ) {
        appendData(id, currentPage);
      }
    }
  };

  const resetData = () => {
    setCurrentPage(1);
    setPageSize(10);
    setTotalRecords(0);
    setData([]);
  };

  return (
    <Content>
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        afterClose={() => resetData()}
        footer={null}
        title={"Likes"}
      >
        <>
          {totalRecords > 0 ? (
            <List
              bordered
              loading={profileLoading}
              header={<b>Total: {totalRecords}</b>}
              footer={null}
            >
              <VirtualList
                data={data}
                height={ContainerHeight}
                itemHeight={47}
                itemCount={data.length}
                itemKey="id"
                onScroll={onScroll}
              >
                {(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      avatar={
                        <>
                          {item.user &&
                          item.user.profilePictureDetails &&
                          item.user.profilePictureDetails &&
                          item.user.profilePictureDetails.filePath ? (
                            <Avatar
                              src={
                                item.user &&
                                item.user.profilePictureDetails &&
                                item.user.profilePictureDetails &&
                                item.user.profilePictureDetails.filePath
                              }
                            />
                          ) : (
                            <Avatar icon={<UserOutlined />} />
                          )}
                        </>
                      }
                      title={
                        <a
                          onClick={() => {
                            navigate(
                              `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${item.user.userId}`
                            );
                          }}
                        >
                          <UserTooltip
                            userName={item?.user?.userName || "-"}
                            userId={item.user.userId}
                            verified={item?.user?.verified}
                          />
                        </a>
                      }
                    />
                    <div>
                      {(item?.createdAt &&
                        getFormatDateWithTimeStamp(item?.createdAt)) ||
                        "-"}
                    </div>
                  </List.Item>
                )}
              </VirtualList>
            </List>
          ) : (
            <Empty></Empty>
          )}
        </>
      </Modal>
    </Content>
  );
};
export default CommentReactionList;
