import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Divider,
  Empty,
  Layout,
  List,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import VirtualList from "rc-virtual-list";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFormatDateWithTimeStamp,
  storageConstants,
} from "../../common/utils";
import UserTooltip from "../common/user-tooltip/userTooltip";
import { getReportedCommentUsers } from "./Post.service";

const ContainerHeight = 400;
const { Content } = Layout;
const { Text } = Typography;

const ViewReportedPostCommentUsers = ({
  modalVisible,
  setModalVisible,
  commentId,
  postId = null,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [profileLoading, setProfileLoading] = useState(false);

  useEffect(() => {
    if (commentId && modalVisible) {
      appendData(commentId, 1);
    }
    return () => {
      resetData();
    };
  }, [modalVisible]);

  const appendData = (commentId, offset) => {
    setProfileLoading(true);
    setTotalRecords(0);
    getReportedCommentUsers(commentId, offset, pageSize)
      .then((data) => {
        formatData(data);
      })
      .catch((error) => {
        setProfileLoading(false);
        message.error(error.message ? error.message : "failed");
      });
  };

  const formatData = (data) => {
    setProfileLoading(false);
    if (data && data.success) {
      setData((prevData) => [...prevData, ...data.data.rows]);
      setTotalRecords(data.data.count);
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      message.error(data.message ? data.message : "failed");
    }
  };
  const onScroll = (e) => {
    const container = e.currentTarget;
    if (container) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const currentTotal = (currentPage - 1) * pageSize;
      if (
        scrollHeight - scrollTop === clientHeight &&
        currentTotal < totalRecords
      ) {
        appendData(commentId, currentPage);
      }
    }
  };

  const resetData = () => {
    setCurrentPage(1);
    setPageSize(10);
    setTotalRecords(0);
    setData([]);
  };

  return (
    <Content>
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        afterClose={() => resetData()}
        footer={null}
        title={"Reported By"}
      >
        <>
          {totalRecords > 0 ? (
            <List
              bordered
              loading={profileLoading}
              header={<b>Total: {totalRecords}</b>}
              footer={null}
            >
              <VirtualList
                data={data}
                height={ContainerHeight}
                itemHeight={47}
                itemCount={data.length}
                itemKey="id"
                onScroll={onScroll}
              >
                {(item) => {
                  const {
                    user: { userId, userName, verified = false },
                    reason: { key, value },
                    createdAt,
                  } = item;
                  const { profilePictureDetails: { filePath } = {} } =
                    item.user || {};
                  return (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={filePath ? filePath : undefined}
                            icon={!filePath && <UserOutlined />}
                          />
                        }
                        title={
                          <Space split={<Divider type="vertical" />}>
                            <a
                              onClick={() => {
                                navigate(
                                  `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${userId}`
                                );
                              }}
                              style={{ color: "black" }}
                            >
                              <UserTooltip
                                userName={userName || "-"}
                                userId={userId}
                                verified={verified}
                              />
                            </a>
                            <Text type="secondary" style={{ fontSize: "10px" }}>
                              {" "}
                              {getFormatDateWithTimeStamp(createdAt) || "-"}
                            </Text>
                          </Space>
                        }
                        description={value ? value : key || ""}
                      />
                    </List.Item>
                  );
                }}
              </VirtualList>
            </List>
          ) : (
            <Empty></Empty>
          )}
        </>
      </Modal>
    </Content>
  );
};
export default ViewReportedPostCommentUsers;
