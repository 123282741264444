import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Input,
  Layout,
  Space,
  Table,
  message
} from "antd";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import { storageConstants } from "../../common/utils";

import moment, { duration } from "moment";
import { useRef } from "react";
import useExport from "../../hooks/useExport";
import { cancelRequest } from "../../services/api";
import UserTooltip from "../common/user-tooltip/userTooltip";
import DeleteAction from "./DeleteAction";
import { deleteStory, getStoriesList } from "./StoryManagement.service";

const { Content } = Layout;

const StoryManagement = () => {
  const [storiesData, setStoriesData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const navigate = useNavigate();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    getReelsData();
    return () => {
      cancelRequest();
    };
  }, []);

  const getReelsData = () => {
    getStoriesList()
      .then((data) => {
        if (data && data.success) {
          setStoriesData(
            data.data.rows.map((row) => ({
              ...row,
              ...row.user,
              ...row.follow,
            }))
          );
        } else {
          message.error(data.message ? data.message : "failed");
        }
      })
      .catch((error) => {
        message.error(error.message ? error.message : "failed");
      });
  };

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "filePath",
      key: "filePath",
      render: (text, record) => (
        <>
          {record.filePath.length > 0 && (
            <img src={record.filePath} height={100} width={100} />
          )}
        </>
      ),
    },
    {
      title: "Author",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),
      render: (text, record) => (
        <a
          onClick={() => {
            navigate(
              `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${record.user_id}`
            );
          }}
        >
          <UserTooltip userName={text?.userName} userId={record.user_id} verified={false} />
        </a>
      ),
    },
    {
      title: "Views",
      dataIndex: "views_count",
      key: "views_count",
      sorter: (a, b) => a.views_count - b.views_count,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Likes",
      dataIndex: "likes_count",
      key: "likes_count",
      sorter: (a, b) => a.likes_count - b.likes_count,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Comments",
      dataIndex: "comments_count",
      key: "comments_count",
      sorter: (a, b) => a.comments_count - b.comments_count,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Shares",
      dataIndex: "shares_count",
      key: "shares_count",
      sorter: (a, b) => a.shares_count - b.shares_count,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Report",
      dataIndex: "report_count",
      key: "report_count",
      sorter: (a, b) => a.report_count - b.report_count,
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Expries At",
      dataIndex: "expiresAt",
      key: "expiresAt",
      render: (text, record) =>
        moment(record.createdAt)
          .add(duration(1, "hours"))
          .format("DD-MM-YYYY HH:mm:ss"),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button
            shape="circle"
            onClick={(e) =>
              navigate(`/post`, { state: { id: record.id, type: "story" } })
            }
          >
            <EyeOutlined />
          </Button>
          <DeleteAction
            record={record}
            activateDeactivateUserAccount={deleteStory}
            getUsersData={getReelsData}
          />
        </Space>
      ),
    },
  ];
  let columns_dup = [...columns];
  columns_dup.pop();
  const { onCsvPrint, onExcelPrint, onPdfPrint } = useExport({
    columns: columns_dup,
    data: storiesData,
    fileName: "Yandexgram_users",
  });

  return (
    <Content>
      <Card
        title="Stories Management"
        extra={
          <Space>
            {/* <Button type="primary" onClick={onExcelPrint} >Export Excel</Button>
          <Button type="primary" onClick={onCsvPrint} >Export CSV</Button>
          <Button type="primary" onClick={onPdfPrint} >Export PDF</Button> */}
            {/* <Select
            defaultValue={
              storageConstants.PAGES.USER_MANAGEMENT_OPTIONS[0].value
            }
            style={{ width: "120px" }}
            options={storageConstants.PAGES.USER_MANAGEMENT_OPTIONS}
          /> */}
          </Space>
        }
      >
        <Table
          columns={columns}
          dataSource={storiesData}
          scroll={{
            x: 2000,
            y: 500,
          }}
        />
      </Card>
    </Content>
  );
};
export default StoryManagement;
