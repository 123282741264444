import React, {useState} from 'react';
import { Layout, Space, Table, Input, Card, Button, Select, message, Typography, Row, Col, Popover } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { RiDeleteBin2Line } from "react-icons/ri"

const DeleteAction = ({record,activateDeactivateUserAccount,getUsersData}) => {
    const [open, setOpen] = useState(false);
    const hide = () => {
      setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
      setOpen(newOpen);
    };
    return (
        <Space size="middle">
          <Popover
            content={<Row>
              <Col span={4}><Button onClick={hide}>Cancel</Button></Col>
              <Col span={4} offset={8}>
                <Button
                  onClick={() => {
                    activateDeactivateUserAccount(record.id)
                      .then((data) => {
                        if (data && data.success) {
                          message.success(data.message ? data.message : "success");
                          getUsersData();
                        } else {
                          message.error(data.message ? data.message : "failed");
                        }
                      })
                      .catch((error) => {
                        message.error(error.message ? error.message : "failed");
                    }).finally(() => {
                        hide();
                    });
                  }} type="primary" danger>Yes</Button>
              </Col>
            </Row>}
            title={`Are you sure  want to ${record.status === 'active' ? 'Deactivate' : "Activate"} ?`}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          > <Button
          shape='circle'>{record.deletedAt === null ? <DeleteOutlined/> : <RiDeleteBin2Line/>}</Button></Popover>
          {/* <a onClick={() => {
            deleteUserAccount(record.id)
              .then((data) => {
                if (data && data.success) {
                  message.success(data.message ? data.message : "success");
                  getUsersData();
                } else {
                  message.error(data.message ? data.message : "failed");
                }
              })
              .catch((error) => {
                message.error(error.message ? error.message : "failed");
              });
          }}>{record.isDeleted ? "UnDelete" : "Delete"}</a> */}
        </Space>
    );
};

export default DeleteAction;