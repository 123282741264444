import {
  Badge,
  Button,
  Card,
  Descriptions,
  Layout,
  Modal,
  Tag,
  message,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFormatDateWithTimeStamp,
  storageConstants,
} from "../../../common/utils";
import { Image } from "../../../common/utils/image";
import EditRequestVerification from "./EditRequestVerification";
import { getRequestVerificationDetailsById } from "./RequestVerification.service";
import { cancelRequest } from "../../../services/api";
import UserTooltip from "../../common/user-tooltip/userTooltip";
const { Content } = Layout;
const { Paragraph } = Typography;

const ViewRequestVerification = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [verificationData, setVerificationData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [documentType, setDocumentType] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getRequestVerificationDetails(params.id);
    return () => {
      cancelRequest();
    };
  }, []);

  const getRequestVerificationDetails = (id) => {
    getRequestVerificationDetailsById(id)
      .then((data) => {
        if (data && data.success) {
          setVerificationData(data.data);
        } else {
          message.error(data.message ? data.message : "failed");
        }
      })
      .catch((error) => {
        message.error(error.message ? error.message : "failed");
      });
  };

  const getStatus = (status) => {
    switch (status) {
      case "Viewed":
        return "processing";
        break;
      case "Approved":
        return "success";
        break;
      case "Rejected":
        return "error";
        break;
      default:
        return "warning";
        break;
    }
  };

  const getFileExtension = (url) => {
    const lastDotIndex = url.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      setDocumentType(
        url.substring(lastDotIndex + 1) === "pdf" ? "pdf" : "image"
      );
      setShowModal(true);
    }
    return null;
  };

  const editHandler = (value, isUpdated = false) => {
    setIsEdit(value);
    if (isUpdated) {
      getRequestVerificationDetails(params.id);
    }
  };

  return (
    <Content>
      <Card
      // title="Request Verification Details"
      >
        <Descriptions
          bordered
          title="Request Verification Details"
          size={"small"}
          extra={
            <Button type="primary" onClick={() => setIsEdit(true)}>
              Edit
            </Button>
          }
        >
          {verificationData && (
            <>
              <Descriptions.Item label="Full Name">
                {verificationData.fullName || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Badge
                  status={
                    verificationData.status
                      ? getStatus(verificationData.status)
                      : "default"
                  }
                  text={verificationData.status || "-"}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Category">
                {verificationData?.category?.name || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Document Type">
                {verificationData.documentType?.name || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Document">
                <Button
                  type="primary"
                  onClick={() => getFileExtension(verificationData.documentUrl)}
                  disabled={!verificationData.documentUrl}
                >
                  View
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label="Nationality">
                {(verificationData.nationality &&
                  verificationData.nationality.name) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Audience">
                {verificationData.audience || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Industry Type">
                {verificationData?.industryType?.name || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Submitted At">
                {(verificationData.submittedAt &&
                  getFormatDateWithTimeStamp(verificationData.submittedAt)) ||
                  "-"}
              </Descriptions.Item>

              <Descriptions.Item label="Approved At">
                {(verificationData.approvedAt &&
                  getFormatDateWithTimeStamp(verificationData.approvedAt)) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Approved By">
                {verificationData.approvedBy || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Rejected At">
                {(verificationData.rejectedAt &&
                  getFormatDateWithTimeStamp(verificationData.rejectedAt)) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Rejected By">
                {verificationData.rejectedBy || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Comments">
                <Paragraph
                  ellipsis={{ rows: 1, expandable: true, symbol: "more" }}
                >
                  {verificationData.comments || "-"}
                </Paragraph>
                {/* {verificationData.comments || "-"} */}
              </Descriptions.Item>
              {verificationData.links &&
                verificationData.links.map((item, index) => {
                  return (
                    <Descriptions.Item
                      key={index}
                      label={"Link Type " + item.type}
                    >
                      {item.url || "-"}
                    </Descriptions.Item>
                  );
                })}
            </>
          )}
        </Descriptions>
        <div style={{ marginTop: "20px" }}>
          <Descriptions bordered title="User Details" size={"small"}>
            {verificationData && verificationData.user && (
              <>
                <Descriptions.Item label="Full Name">
                  {verificationData.user?.fullName || "-"}
                </Descriptions.Item>

                <Descriptions.Item label="User Name">
                  <a
                    onClick={() => {
                      navigate(
                        `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${verificationData?.user?.userId}`
                      );
                    }}
                  >
                    <UserTooltip
                      userName={verificationData?.user?.userName || "-"}
                      userId={verificationData?.user?.userId}
                      verified={verificationData?.user?.verified}
                    />
                  </a>
                </Descriptions.Item>
                <Descriptions.Item label="Account Type">
                  <Tag
                    color={
                      verificationData?.user?.isPrivate ? "#2db7f5" : "#87d068"
                    }
                  >
                    {verificationData?.user?.isPrivate ? "Private" : "Public"}
                  </Tag>
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </div>
        <Modal
          title="Document"
          open={showModal && verificationData.documentUrl}
          onCancel={() => {
            setShowModal(false);
          }}
          footer={null}
        >
          <Image url={verificationData.documentUrl} type={documentType}></Image>
        </Modal>

        {isEdit && (
          <EditRequestVerification
            props={props}
            isShow={isEdit}
            id={verificationData.id}
            name={verificationData.fullName}
            status={verificationData.status}
            isShowHandler={editHandler}
          />
        )}
      </Card>
    </Content>
  );
};
export default ViewRequestVerification;
