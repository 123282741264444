import { formatTablePayload } from "../../../common/utils";
import { getApi, patchApi, postApi } from "../../../services/api";

export const getRequestVerificationList = (tableParams, status) => {
  const data = {
    ...formatTablePayload(tableParams),
    status: status,
  };
  return postApi(`/request-verification-admin`, data, null, "/api/v1/user");
};

export const getRequestVerificationDetailsById = (id) =>
  getApi(`/request-verification/${id}`, "/api/v1/user");

export const getRequestVerificationListByStatus = (status) =>
  getApi(`/request-verification?status=${status}`, "/api/v1/user");

export const updateRequestVerificationStatus = (id, data) =>
  patchApi(`/request-verification/${id}`, data, null, "/api/v1/user");

export const getNationality = () => getApi(`/nationality-list`, "/api/v1");

export const getCategories = () =>
  getApi(`/request-verification-list`, "/api/v1/user");
