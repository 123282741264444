import types from "./types";

const setAuthUser = (user) => ({
  type: types.AUTH_USER,
  status: "success",
  payload: {
    user: user,
  },
});

const updateAuthProps = (data) => ({
  type: `UPDATE_${types.AUTH_USER}`,
  status: "success",
  payload: {
   ...data,
  },
});

const resetAuthProps = () => ({
  type: `RESET_${types.AUTH_USER}`,
  status: "success",
  payload: {},
});

export default {
  setAuthUser,
  updateAuthProps,
  resetAuthProps
};
