import { EyeOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Empty,
  Layout,
  List,
  Modal,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
  message,
  Input,
} from "antd";
import VirtualList from "rc-virtual-list";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFormatDateWithTimeStamp,
  storageConstants,
} from "../../common/utils";
import UserTooltip from "../common/user-tooltip/userTooltip";
import {
  getPaginatedBlockList,
  getPaginatedBlockListOfUser,
} from "./Blocklist.service";
import useFilters from "../common/table/useFilters";
const { Search } = Input;
const ContainerHeight = 400;
const { Content } = Layout;

const BLOCKLIST_TABS = {
  BLOCKED: {
    title: "Blocked",
    key: "blocked",
  },
  BLOCKED_BY: {
    title: "Blocked By",
    key: "blockedBy",
  },
};

const Blocklist = (props) => {
  const navigate = useNavigate();
  const [blocklistData, setBlocklistData] = useState([]);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(BLOCKLIST_TABS.BLOCKED_BY.key);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [userId, setUserId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalTableRecords, setTotalTableRecords] = useState(0);
  const [profileLoading, setProfileLoading] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
      pageSize: 10,
    },
  });
  const { getColumnSearchProps } = useFilters();

  useEffect(() => {
    if (trigger) {
      getContent();
    }
    return () => {
      resetData();
    };
  }, [JSON.stringify(tableParams)]);

  const getContent = () => {
    setLoading(true);
    getPaginatedBlockList(getTableParams(tableParams))
      .then((data) => {
        if (data && data.success) {
          setTotalTableRecords(data.data.count);
          setBlocklistData(data.data.rows);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: data.data.count,
            },
          });
        } else {
          message.error(data.message ? data.message : "failed");
        }
      })
      .catch((error) => {
        message.error(error.message ? error.message : "failed");
      })
      .finally(() => {
        setTrigger(false);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "user.userName",
      key: "userName",
      width: 200,
      fixed: "left",
      ellipsis: true,
      ...getColumnSearchProps("user", "userName"),
      render: (text, record) => (
        <a
          onClick={() => {
            navigate(
              `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${record.block_user_id}`
            );
          }}
        >
          <UserTooltip
            userName={record?.user?.userName}
            userId={record.block_user_id}
            verified={record?.user?.verified}
          />
        </a>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "user.fullName",
      key: "fullName",
      width: 200,
      ellipsis: true,
      ...getColumnSearchProps("user", "fullName"),
      render: (text, record) => (
        <Typography.Text>{record?.user?.fullName || "-"}</Typography.Text>
      ),
    },
    {
      title: "Account Type",
      dataIndex: "isPrivate",
      key: "isPrivate",
      width: 200,
      render: (text, record) => (
        <Typography.Text>
          <Tag color={record?.user?.isPrivate ? "#2db7f5" : "#87d068"}>
            {record?.user?.isPrivate ? "Private" : "Public"}
          </Tag>
        </Typography.Text>
      ),
      filters: [
        {
          text: "Private",
          value: true,
        },
        {
          text: "Public",
          value: false,
        },
      ],
      onFilter: (value, record) => record?.user?.isPrivate === value,
    },
    {
      title: "Blocked By Count",
      dataIndex: "count_of_blocked_by",
      key: "count_of_blocked_by",
      width: 200,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Button
            shape="circle"
            onClick={(e) => modalHandler(record.block_user_id)}
          >
            <EyeOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTrigger(true);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getTableParams = (params) => ({
    pageSize: params.pagination?.pageSize,
    current: params.pagination?.current,
    ...params,
  });

  const appendData = (id, currentTab, offset, search) => {
    setProfileLoading(true);
    setTotalRecords(0);
    getPaginatedBlockListOfUser(id, currentTab, offset, pageSize, search)
      .then((data) => {
        formatData(data);
      })
      .catch((error) => {
        setProfileLoading(false);
        message.error(error.message ? error.message : "failed");
      });
  };

  const formatData = (data) => {
    setProfileLoading(false);
    if (data && data.success) {
      setData((prevData) => [...prevData, ...data.data.rows]);
      setTotalRecords(data.data.count);
      setCurrentPage((prevPage) => prevPage + 1);
      message.success(`${data.data.rows.length} more profiles loaded!`);
    } else {
      message.error(data.message ? data.message : "failed");
    }
  };
  const onScroll = (e) => {
    const container = e.currentTarget;
    if (container) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const currentTotal = (currentPage - 1) * pageSize;
      if (
        scrollHeight - scrollTop === clientHeight &&
        currentTotal < totalRecords
      ) {
        appendData(userId, tab, currentPage, searchText);
      }
    }
  };

  const modalHandler = (id) => {
    resetData();
    setUserId(id);
    setModalVisible(true);
    appendData(id, tab, 1, "");
  };
  const resetData = () => {
    setTab(BLOCKLIST_TABS.BLOCKED_BY.key);
    setUserId("");
    setSearchText("");
    setCurrentPage(1);
    setPageSize(10);
    setTotalRecords(0);
    setData([]);
  };

  const onSearch = (value, event) => {
    event.preventDefault();
    setSearchText(value);
    setProfileLoading(true);
    setCurrentPage(1);
    setData([]);
    appendData(userId, tab, 1, value);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const viewContainer = () => {
    return (
      <>
        <List
          bordered
          header={
            <>
              <Search
                placeholder="Search..."
                onSearch={onSearch}
                defaultValue={""}
                value={searchText}
                onChange={handleSearchChange}
                allowClear
                enterButton
                disabled={profileLoading || (!searchText && totalRecords < 2)}
              />
            </>
          }
          loading={profileLoading}
          footer={
            <div style={{ float: "right" }}>
              <b>Total: {totalRecords}</b>
            </div>
          }
        >
          {totalRecords > 0 ? (
            <VirtualList
              data={data}
              height={ContainerHeight}
              itemHeight={47}
              itemCount={data.length}
              itemKey="id"
              onScroll={onScroll}
            >
              {(item) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      <>
                        {item.user &&
                        item.user.profilePictureDetails &&
                        item.user.profilePictureDetails &&
                        item.user.profilePictureDetails.filePath ? (
                          <Avatar
                            src={
                              item.user &&
                              item.user.profilePictureDetails &&
                              item.user.profilePictureDetails &&
                              item.user.profilePictureDetails.filePath
                            }
                          />
                        ) : (
                          <Avatar icon={<UserOutlined />} />
                        )}
                      </>
                    }
                    title={
                      <a
                        onClick={() => {
                          navigate(
                            `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${item.user.userId}`
                          );
                        }}
                      >
                        @{item?.user?.userName || "-"}
                      </a>
                    }
                  />
                  <div>
                    {(item?.createdAt &&
                      getFormatDateWithTimeStamp(item?.createdAt)) ||
                      "-"}
                  </div>
                </List.Item>
              )}
            </VirtualList>
          ) : (
            <Empty></Empty>
          )}
        </List>
      </>
    );
  };

  const items = [
    {
      key: BLOCKLIST_TABS.BLOCKED_BY.key,
      label: BLOCKLIST_TABS.BLOCKED_BY.title,
      children: viewContainer(),
    },
    {
      key: BLOCKLIST_TABS.BLOCKED.key,
      label: BLOCKLIST_TABS.BLOCKED.title,
      children: viewContainer(),
    },
  ];

  const onTabChange = (event) => {
    setTab(event);
    setSearchText("");
    setCurrentPage(1);
    setPageSize(10);
    setTotalRecords(0);
    setData([]);
    appendData(userId, event, 1, "");
  };
  return (
    <Content>
      <Card title={"Blocklist"}>
        <Table
          columns={columns}
          dataSource={blocklistData}
          pagination={tableParams.pagination}
          loading={loading}
          footer={() => (
            <>
              <b>Total: {totalTableRecords}</b>
            </>
          )}
          onChange={handleTableChange}
          scroll={{
            y: 400,
          }}
        />
      </Card>
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        afterClose={() => resetData()}
        footer={null}
      >
        <Tabs
          defaultActiveKey={tab}
          activeKey={tab}
          items={items}
          onChange={onTabChange}
        />
      </Modal>
    </Content>
  );
};
export default Blocklist;
