import { EyeOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Empty,
  Layout,
  List,
  Modal,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import VirtualList from "rc-virtual-list";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFormatDateWithTimeStamp,
  storageConstants,
} from "../../common/utils";
import { cancelRequest } from "../../services/api";
import UserTooltip from "../common/user-tooltip/userTooltip";
import {
  getReportedPaginatedUsers,
  getReportedUsersForUser,
} from "./ContentReportManagement.service";
const { Content } = Layout;
const ContainerHeight = 400;

const UserReportTable = ({ tab }) => {
  const navigate = useNavigate();
  const [reportedData, setReportedData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [userId, setUserId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [profileLoading, setProfileLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    if (tab === "user") {
      getContent();
    }
    return () => {
      setReportedData([]);
      setCurrentPage(1);
      setPageSize(10);
      setTotalRecords(0);
      cancelRequest();
    };
  }, [tab]);

  const getContent = () => {
    setLoading(true);
    getReportedPaginatedUsers(tableParams, tab)
      .then((data) => {
        if (data && data.success) {
          setReportedData(data.data.rows);
        } else {
          message.error(data.message ? data.message : "failed");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message ? error.message : "failed");
      });
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      render: (text, record) => (
        <a
          onClick={() => {
            navigate(
              `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${record.user_id}`
            );
          }}
        >
          <UserTooltip
            userName={record?.user?.userName}
            userId={record.user_id}
            verified={record?.user?.verified}
          />
        </a>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, record) => (
        <Typography.Text>{record?.user?.fullName || "-"}</Typography.Text>
      ),
    },
    {
      title: "Report Count",
      dataIndex: "count_of_reports",
      key: "count_of_reports",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" onClick={(e) => modalHandler(record.user_id)}>
            <EyeOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const appendData = (id) => {
    setProfileLoading(true);
    const offset = currentPage;
    getReportedUsersForUser(id, offset, pageSize)
      .then((data) => {
        formatData(data);
      })
      .catch((error) => {
        setProfileLoading(false);
        message.error(error.message ? error.message : "failed");
      });
  };

  const formatData = (data) => {
    setProfileLoading(false);
    if (data && data.success) {
      setData((prevData) => [...prevData, ...data.data.rows]);
      setTotalRecords(data.data.count);
      setCurrentPage((prevPage) => prevPage + 1);
      message.success(`${data.data.rows.length} more profiles loaded!`);
    } else {
      message.error(data.message ? data.message : "failed");
    }
  };
  const onScroll = (e) => {
    const container = e.currentTarget;
    if (container) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const currentTotal = (currentPage - 1) * pageSize;
      if (
        scrollHeight - scrollTop === clientHeight &&
        currentTotal < totalRecords
      ) {
        appendData(userId);
      }
    }
  };

  const modalHandler = (id) => {
    setUserId(id);
    setModalVisible(true);
    appendData(id);
  };
  const resetData = () => {
    setUserId("");
    setCurrentPage(1);
    setPageSize(10);
    setTotalRecords(0);
    setData([]);
  };
  return (
    <Content>
      <Card title={"Reported Users"}>
        <Table
          columns={columns}
          dataSource={reportedData}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{
            y: 500,
          }}
        />
      </Card>
      <Modal
        title="Reported By"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        // onOk={handleOk}
        afterClose={resetData}
        footer={null}
      >
        {totalRecords > 0 ? (
          <List
            bordered
            loading={profileLoading}
            header={<b>Total: {totalRecords}</b>}
            footer={null}
          >
            <VirtualList
              data={data}
              height={ContainerHeight}
              itemHeight={47}
              itemCount={data.length}
              itemKey="id"
              onScroll={onScroll}
            >
              {(item) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      <>
                        {item.user &&
                        item.user.profilePictureDetails &&
                        item.user.profilePictureDetails &&
                        item.user.profilePictureDetails.filePath ? (
                          <Avatar
                            src={
                              item.user &&
                              item.user.profilePictureDetails &&
                              item.user.profilePictureDetails &&
                              item.user.profilePictureDetails.filePath
                            }
                          />
                        ) : (
                          <Avatar icon={<UserOutlined />} />
                        )}
                      </>
                    }
                    title={
                      <a
                        onClick={() => {
                          navigate(
                            `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${item.user_id}`
                          );
                        }}
                      >
                        @{item?.user?.userName || "-"}
                      </a>
                    }
                    // description={(tab==='reported'?(item?.reason || "") :(item?.user?.fullName ||"") )}
                  />
                  <div>
                    {(item?.createdAt &&
                      getFormatDateWithTimeStamp(item?.createdAt)) ||
                      "-"}
                  </div>
                </List.Item>
              )}
            </VirtualList>
          </List>
        ) : (
          <Empty></Empty>
        )}
      </Modal>
    </Content>
  );
};
export default UserReportTable;
