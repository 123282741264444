import { Breadcrumb, Layout, theme } from "antd";
import { useState } from "react";
import { Header, SideMenu, Footer } from "../index";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Content } = Layout;

const BaseLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

 const breadCrumbClickHandler=(event,path,title)=>{
  event.preventDefault()
  props.updateAuthUser({path:path,currentMenuTitle:title})
  navigate(path)
  }

  const getMenu=()=>{
    if(props && props.user && props.user.path && props.user.path!=='/'){
      if(props.user.breadCrumbPath){
        return [{
          title: <a onClick={(e)=>breadCrumbClickHandler(e,'/',null)} href="/"><HomeOutlined /></a> ,
        },
        {
          title:<a onClick={(e)=>breadCrumbClickHandler(e,props['user']['path'] || '/', props['user']['currentMenuTitle'] || '')}>{props['user']['currentMenuTitle'] || ''}</a>
        },
        {
          title: props['user']['breadCrumbPath'] || '',
        }
      ]
      }else{
        return [{
          title: <a onClick={(e)=>breadCrumbClickHandler(e,'/',null)}><HomeOutlined /></a> ,
        },
        {
          title: props['user']['currentMenuTitle'] || '',
        }
      ]
      }
    }else{
      return [{
        href: '/',
        title: <HomeOutlined />,
      }]
    }
  }

  return (
    <Layout style={{ height: "100vh" }}>
      <SideMenu {...props} collapsed={collapsed}></SideMenu>
      <Layout>
        <Header {...props} collapsed={collapsed} setCollapsed={setCollapsed}></Header>
        <Breadcrumb  style={{
            marginLeft: "16px",
          }} items={getMenu()} />
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
           
          {props.content}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};
export default BaseLayout;
