import { Button, Card, Layout, Popconfirm, message } from "antd";
import React from "react";
import { deletePostByAdmin, deleteReelByAdmin } from "./Post.service";
import ViewProfiles from "./ViewProfiles";
import ViewReportedPostComment from "./ViewReportedPostComment";
import { useState } from "react";
import ViewReportedPostUsers from "./ViewRepostedPostUsers";
const { Content } = Layout;
const { Meta } = Card;

const ContainerHeight = 400;
const ViewReported = ({ tab, type, id, data }) => {
  const [reportTab, setReportTab] = useState("comment");
  const confirm = (e) => {
    if (type === "post") {
      deletePostByAdmin(id)
        .then((data) => {
          if (data && data.success) {
            message.success(`${data.message}`);
          } else {
            message.error(data.message ? data.message : "failed");
          }
        })
        .catch((error) => {
          message.error(error.message ? error.message : "failed");
        });
    } else if (type === "reel") {
      deleteReelByAdmin(id)
        .then((data) => {
          if (data && data.success) {
            message.success(`${data.message}`);
          } else {
            message.error(data.message ? data.message : "failed");
          }
        })
        .catch((error) => {
          message.error(error.message ? error.message : "failed");
        });
    }
  };
  const cancel = (e) => {
    return 0;
  };
  const onTabChange = (key) => {
    setReportTab(key);
  };
  return (
    <>
      <Layout style={{ background: "white" }}>
        <Card
          tabList={[
            {
              key: "comment",
              tab: "Comments",
            },
            {
              key: "post",
              tab: "Post",
            },
          ]}
          activeTabKey={reportTab}
          onTabChange={onTabChange}
          tabBarExtraContent={
            <Popconfirm
              title="Are you sure?"
              description={
                data.deletedAt
                  ? `Active this ${data.type.toUpperCase()}`
                  : `Deactivate this ${data.type.toUpperCase()}`
              }
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type={"primary"} danger={data.deletedAt ? false : true}>
                {data.deletedAt ? "Active" : "Deactivate"}
              </Button>
            </Popconfirm>
          }
        >
          {reportTab === "comment" && (
            <ViewReportedPostComment
              tab={"comment"}
              id={id}
              type={data?.type}
            />
          )}
          {reportTab === "post" && (
            <ViewReportedPostUsers tab={"post"} id={id} />
          )}
        </Card>
      </Layout>
    </>
  );
};
export default ViewReported;
