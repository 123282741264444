import Moment from "moment";
import { storageConstants } from "./constants";

export const getFormatDateWithTimeStamp = (data) => {
  return Moment(data).utc().format("DD-MM-YYYY HH:MM:SS");
};

export const getFormatDate = (data) => {
  return Moment(data).format("DD-MM-YYYY");
};

export const getAge = (data) => {
  return Moment().diff(data, "years");
};

export const flattenObject = (obj) => {
  const result = {};

  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      result[key] = obj[key][0];
    } else {
      result[key] = obj[key];
    }
  }

  return result;
};

export const formatTablePayload = (tableParams) => {
  const { current = 1, pageSize = 10 } = tableParams;
  const data = {
    page: current,
    pageSize: pageSize,
    filters: tableParams?.filters ? flattenObject(tableParams.filters) : null,
    sort:
      tableParams && tableParams?.field && tableParams?.order
        ? [tableParams?.field, tableParams?.order === "ascend" ? "asc" : "desc"]
        : ["createdAt", "desc"],
  };
  return data;
};

export { storageConstants };
