import { UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Divider, Empty, Layout, List, Space, Tag, message } from "antd";
import VirtualList from "rc-virtual-list";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { storageConstants } from "../../common/utils";
import { getTaggedUsers } from "./Post.service";
import UserTooltip from "../common/user-tooltip/userTooltip";
const ContainerHeight = 400;

const ViewTaggedProfiles = ({ tab, type, id, data }) => {
  const [profileData, setProfileData] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [profileLoading, setProfileLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (tab === "tags") {
      setProfileData([]);
      setTotalRecords(0);
      appendData();
    }
    return () => {
      setProfileData([]);
      setTotalRecords(0);
    };
  }, [tab]);

  const appendData = () => {
    console.log(data);
    const tags = data?.fileData[0]?.tags || [];
    const userId = data?.user?.userId;
    if (tags && tags.length) {
      setProfileLoading(true);
      getTaggedUsers(userId, { users: tags })
        .then((data) => {
          setProfileLoading(false);
          setProfileData(data.data);
          setTotalRecords(data.data.length);
          message.success(`tagged users successfully loaded`);
        })
        .catch((error) => {
          setProfileLoading(false);
          message.error(error.message ? error.message : "failed");
        });
    } else {
      setProfileData([]);
      setTotalRecords(0);
    }
  };

  return (
    <>
      <Layout style={{ background: "white" }}>
        {totalRecords > 0 ? (
          <List
            bordered
            loading={profileLoading}
            footer={
              <div style={{ float: "right" }}>
                <b>Total: {totalRecords}</b>
              </div>
            }
          >
            <VirtualList
              data={profileData}
              height={ContainerHeight}
              itemHeight={47}
              itemCount={data.length}
              itemKey="id"
            >
              {(item) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      <>
                        {item &&
                        item.profilePictureDetails &&
                        item.profilePictureDetails.filePath ? (
                          <Avatar
                            src={
                              item &&
                              item.profilePictureDetails &&
                              item.profilePictureDetails.filePath
                            }
                          />
                        ) : (
                          <Avatar icon={<UserOutlined />} />
                        )}
                      </>
                    }
                    title={
                      <a
                        onClick={() => {
                          navigate(
                            `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${item.userId}`
                          );
                        }}
                      >
                        <Space split={<Divider type="vertical" />}>
                          <UserTooltip
                            userName={item?.userName || "-"}
                            userId={item.userId}
                            verified={item?.verified}
                            
                          />
                          {item?.isFollowing && (
                            <Tag color="green">Following</Tag>
                          )}
                        </Space>
                      </a>
                    }
                    description={item?.fullName || ""}
                  />
                </List.Item>
              )}
            </VirtualList>
          </List>
        ) : (
          <Card>
            <Empty></Empty>
          </Card>
        )}
      </Layout>
    </>
  );
};
export default ViewTaggedProfiles;
