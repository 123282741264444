import {
    Button,
    Form,
    Input,
    Layout,
    Modal,
    Select,
    Space,
    message,
} from "antd";
import { useState } from "react";
import {
    storageConstants
} from "../../../common/utils";
import { updateRequestVerificationStatus } from "./RequestVerification.service";
const { Content } = Layout;
const { TextArea } = Input;

const EditRequestVerification = ({
  props,
  isShow,
  id,
  name,
  status,
  isShowHandler,
}) => {
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(isShow);
  const [statusOptions, setStatusOptions] = useState(
    storageConstants.PAGES.REQUEST_VERIFICATION_OPTIONS.slice(1)
  );

  form.setFieldsValue({
    status: status,
  });

  const handleSubmit = () => {
    const data = {
      status: form.getFieldValue("status"),
      comment: form.getFieldValue("comment"),
      admin: (props && props.user && props.user.fullName) || "admin",
    };
    updateRequestVerificationStatus(id, data)
      .then((data) => {
        if (data && data.success) {
          form.resetFields();
          setShowModal(false);
          isShowHandler(false, true);
          message.success("successfully updated");
        } else {
          isShowHandler(false);
          message.error(data.message ? data.message : "failed");
        }
      })
      .catch((error) => {
        isShowHandler(false);
        message.error(error.message ? error.message : "failed");
      });
  };

  const handleStatusChange = (e) => {
    form.setFieldValue("status", e);
  };

  const cancel = () => {
    form.resetFields();
    setShowModal(false);
    isShowHandler(false);
  };

  return (
    <Content>
      <Modal
        title={"Edit " + name + " Request Verification Status"}
        visible={showModal}
        onCancel={cancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "status is required!",
              },
            ]}
          >
            <Select onChange={handleStatusChange} options={statusOptions} />
          </Form.Item>
          <Form.Item
            label="Comment"
            name="comment"
            rules={[
              {
                required: true,
                message: "comment is required!",
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder="max comment length is 100"
              maxLength={100}
            />
          </Form.Item>

          <Form.Item>
            <Space style={{ float: "right" }}>
              <Button type="default" onClick={cancel}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
};
export default EditRequestVerification;