import { UserOutlined, WarningOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Divider,
  Empty,
  Layout,
  List,
  Space,
  Typography,
  message,
} from "antd";
import VirtualList from "rc-virtual-list";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFormatDateWithTimeStamp,
  storageConstants,
} from "../../common/utils";
import UserTooltip from "../common/user-tooltip/userTooltip";
import { getReportedComments } from "./Post.service";
import ViewReportedPostCommentUsers from "./ViewReportedPostCommentUsers";
const ContainerHeight = 400;
const { Text } = Typography;
const ViewReportedPostComment = ({ tab, id, type = "reel" }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [profileLoading, setProfileLoading] = useState(false);
  const [reportedUserModal, setReportedUserModal] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (tab === "comment") {
      setData([]);
      setCurrentPage(1);
      setPageSize(10);
      setTotalRecords(0);
      appendData();
    }
    return () => {
      setData([]);
      setCurrentPage(1);
      setPageSize(10);
      setTotalRecords(0);
    };
  }, [tab]);

  const appendData = () => {
    setProfileLoading(true);

    const offset = currentPage;
    getReportedComments(id, offset, pageSize, type)
      .then((data) => {
        formatData(data);
      })
      .catch((error) => {
        setProfileLoading(false);
        message.error(error.message ? error.message : "failed");
      });
  };

  const formatData = (data) => {
    setProfileLoading(false);
    if (data && data.success) {
      setData((prevData) => [...prevData, ...data.data.rows]);
      setTotalRecords(data.data.count);
      setCurrentPage((prevPage) => prevPage + 1);
      message.success(`${data.data.rows.length} more profiles loaded!`);
    } else {
      message.error(data.message ? data.message : "failed");
    }
  };
  const onScroll = (e) => {
    const container = e.currentTarget;
    if (container) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const currentTotal = (currentPage - 1) * pageSize;
      if (
        scrollHeight - scrollTop === clientHeight &&
        currentTotal < totalRecords
      ) {
        appendData();
      }
    }
  };

  const reportedUserListModal = (id) => {
    setCommentId(id);
    setReportedUserModal(true);
  };
  return (
    <>
      <Layout style={{ background: "white" }}>
        {totalRecords > 0 ? (
          <List
            bordered
            loading={profileLoading}
            footer={
              <div style={{ float: "right" }}>
                <b>Total: {totalRecords}</b>
              </div>
            }
          >
            <VirtualList
              data={data}
              height={ContainerHeight}
              itemHeight={47}
              itemCount={data.length}
              itemKey="id"
              onScroll={onScroll}
            >
              {(item) => {
                const commentData =
                  item[type === "post" ? "postComment" : "reelComment"] || {};

                const {
                  user: { userId, userName, verified = false },
                  comment,
                  createdAt,
                } = commentData;
                const { profilePictureDetails: { filePath } = {} } =
                  commentData.user || {};

                return (
                  <List.Item
                    key={item.id}
                    actions={[
                      <Space
                        onClick={() => reportedUserListModal(item.comment_id)}
                        style={{ cursor: "pointer" }}
                      >
                        <WarningOutlined />
                      </Space>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={filePath ? filePath : undefined}
                          icon={!filePath && <UserOutlined />}
                        />
                      }
                      title={
                        <Space split={<Divider type="vertical" />}>
                          <a
                            onClick={() => {
                              navigate(
                                `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${userId}`
                              );
                            }}
                            style={{ color: "black" }}
                          >
                            <UserTooltip
                              userName={userName || "-"}
                              userId={userId}
                              verified={verified}
                            />
                          </a>
                          <Text type="secondary" style={{ fontSize: "10px" }}>
                            {" "}
                            {getFormatDateWithTimeStamp(createdAt) || "-"}
                          </Text>
                        </Space>
                      }
                      description={comment || ""}
                    />
                  </List.Item>
                );
              }}
            </VirtualList>
          </List>
        ) : (
          <Card>
            <Empty></Empty>
          </Card>
        )}

        {reportedUserModal && (
          <ViewReportedPostCommentUsers
            modalVisible={reportedUserModal}
            setModalVisible={setReportedUserModal}
            commentId={commentId}
            post={id}
          />
        )}
      </Layout>
    </>
  );
};
export default ViewReportedPostComment;
