import { Avatar, Card, Empty, Layout, List, message } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import VirtualList from "rc-virtual-list";
import { useNavigate } from "react-router-dom";
import {
  getPostLikesByPostId,
  getReelLikesByReelId,
  getReportedUsers,
} from "./Post.service";
import {
  getFormatDateWithTimeStamp,
  storageConstants,
} from "../../common/utils";
import { UserOutlined } from "@ant-design/icons";
const { Content } = Layout;
const { Meta } = Card;
const ContainerHeight = 400;

const ViewProfiles = ({ tab, type, id }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [profileLoading, setProfileLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (tab === "likes" || tab === "reported") {
      setData([]);
      setCurrentPage(1);
      setPageSize(10);
      setTotalRecords(0);
      appendData();
    }
    return () => {
      setData([]);
      setCurrentPage(1);
      setPageSize(10);
      setTotalRecords(0);
    };
  }, [tab]);

  const appendData = () => {
    setProfileLoading(true);
    if (tab === "likes") {
      const offset = currentPage;
      if (type === "post") {
        getPostLikesByPostId(id, offset, pageSize)
          .then((data) => {
            formatData(data);
          })
          .catch((error) => {
            setProfileLoading(false);
            message.error(error.message ? error.message : "failed");
          });
      } else if (type === "reel") {
        getReelLikesByReelId(id, offset, pageSize)
          .then((data) => {
            formatData(data);
          })
          .catch((error) => {
            setProfileLoading(false);
            message.error(error.message ? error.message : "failed");
          });
      }
    } else if (tab === "reported") {
      const offset = currentPage;
      getReportedUsers(id, offset, pageSize)
        .then((data) => {
          formatData(data);
        })
        .catch((error) => {
          setProfileLoading(false);
          message.error(error.message ? error.message : "failed");
        });
    }
  };

  const formatData = (data) => {
    setProfileLoading(false);
    if (data && data.success) {
      setData((prevData) => [...prevData, ...data.data.rows]);
      setTotalRecords(data.data.count);
      setCurrentPage((prevPage) => prevPage + 1);
      message.success(`${data.data.rows.length} more profiles loaded!`);
    } else {
      message.error(data.message ? data.message : "failed");
    }
  };
  const onScroll = (e) => {
    const container = e.currentTarget;
    if (container) {
      const { scrollHeight, scrollTop, clientHeight } = container;
      const currentTotal = (currentPage - 1) * pageSize;
      if (
        scrollHeight - scrollTop === clientHeight &&
        currentTotal < totalRecords
      ) {
        appendData();
      }
    }
  };
  return (
    <>
      <Layout style={{ background: "white" }}>
        {totalRecords > 0 ? (
          <List
            bordered
            loading={profileLoading}
            footer={
              <div style={{ float: "right" }}>
                <b>Total: {totalRecords}</b>
              </div>
            }
          >
            <VirtualList
              data={data}
              height={ContainerHeight}
              itemHeight={47}
              itemCount={data.length}
              itemKey="id"
              onScroll={onScroll}
            >
              {(item) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      <>
                        {item.user &&
                        item.user.profilePictureDetails &&
                        item.user.profilePictureDetails &&
                        item.user.profilePictureDetails.filePath ? (
                          <Avatar
                            src={
                              item.user &&
                              item.user.profilePictureDetails &&
                              item.user.profilePictureDetails &&
                              item.user.profilePictureDetails.filePath
                            }
                          />
                        ) : (
                          <Avatar icon={<UserOutlined />} />
                        )}
                      </>
                    }
                    title={
                      <a
                        onClick={() => {
                          navigate(
                            `${storageConstants.PAGES.ROUTE.USER_MANAGEMENT}/user/${item.userId}`
                          );
                        }}
                      >
                        @{item?.user?.userName || "-"}
                      </a>
                    }
                    // description={(tab==='reported'?(item?.reason || "") :(item?.user?.fullName ||"") )}
                  />
                  <div>
                    {(item?.createdAt &&
                      getFormatDateWithTimeStamp(item?.createdAt)) ||
                      "-"}
                  </div>
                </List.Item>
              )}
            </VirtualList>
          </List>
        ) : (
          <Card>
            <Empty></Empty>
          </Card>
        )}
      </Layout>
    </>
  );
};
export default ViewProfiles;
