import xlsx from "better-xlsx";
import objectPath from "object-path";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import "./font";

const useExport = ({
  columns,
  data,
  fileName,
  pdfTheme ="plain"
}) => {
  const onExcelPrint = () => {
    const file = new xlsx.File();
    const sheet = file.addSheet("data");
    const headerRow = sheet.addRow();
    columns.forEach(({ title, render }) => {
    //   if (render) return;
      const cell = headerRow.addCell();
      cell.value = title;
    });
    data.forEach((record) => {
      const row = sheet.addRow();
      columns.forEach(({ dataIndex, render }) => {
        const cell = row.addCell();
        cell.value = objectPath.get(record, dataIndex).toString();
      });
    });

    file.saveAs('blob').then((blob) => {
      saveAs(blob, `${fileName}.xlsx`);
    })
  };

  const onCsvPrint = () => {
    let csv = "";
    columns.forEach(({ title, render }, index) => {
    //   if (render) return;
      if (index !== 0) csv += ",";

      csv += `${title.replaceAll('"', '""')}`;
    });

    csv += "\n";

    data.forEach((record) => {
      columns.forEach(({ dataIndex, render }, index) => {
        // if (render) return;

        if (index !== 0) csv += ",";

        csv += `${objectPath.get(record, dataIndex ).toString().replaceAll('"', '""')}`;
      });
      csv += "\n";
    });

    saveAs(new Blob([csv]), `${fileName}.csv`);
  }

  const onPdfPrint = () => {
    const doc = new jsPDF();
    doc.setFont('FreeSans');

    autoTable(doc, {
      styles: { font: "FreeSans" },
      headStyles: { fontStyle: 'normal' },
      head: [columns.map(c => c.title)],
      body: data.map(r => columns.map(c => objectPath.get(r, c.dataIndex).toString())),
      theme: pdfTheme,
    })

    doc.save(`${fileName}.pdf`);
  }

  return {
    onExcelPrint,
    onCsvPrint,
    onPdfPrint,
  }
};

export default useExport;